@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Raleway';
  src: url('./fonts/Raleway-VariableFont_wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Anton';
  src: url('./fonts/Anton-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Tourney';
  src: url('./fonts/Tourney-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-size: 12px;
  line-height: 1.5;
  background-color: #000;
  color: #fff;
  /* word-break: break-word; */
  font-family: 'Raleway', sans-serif;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
